import React, { Component } from 'react';
import wifi_tuto_1 from '../../assets/img/faq/reactive_wifi/tuto_1.png';
import wifi_tuto_2 from '../../assets/img/faq/reactive_wifi/tuto_2.png';
import './styles.styl';

class FaqComponent extends Component {
  renderFooter() {
    const {
      wordings: { footer_description, mail },
    } = this.props;
    return (
      <>
        <p>{footer_description}</p>
        <a href={`mailto:${mail}`} className="support">
          {mail}
        </a>
      </>
    );
  }

  renderRow(question, response = [], link = null, images = []) {
    const {
      wordings: { video },
    } = this.props;

    return (
      <>
        <p className="question">{question}</p>
        {response.map(data => (
          <p className="response" dangerouslySetInnerHTML={{ __html: data }} />
        ))}
        <div className="images-wrapper">
          {images.map((src, index) => {
            return <img src={src} key={index} />;
          })}
        </div>
        {link && (
          <a href={link[0]} className="video-link" target="_blank">
            <img src={link[1]} className="video-thumbnail" />
            {video}
          </a>
        )}
        <div className="separator" />
      </>
    );
  }

  render() {
    const {
      wordings: {
        subtitle_1,
        subtitle_2,
        tuto_question_1,
        tuto_response_1,
        tuto_link_1,
        tuto_thumbnail_1,
        tuto_question_2,
        tuto_response_2,
        tuto_link_2,
        tuto_thumbnail_2,
        tuto_question_3,
        tuto_response_3,
        tuto_question_4,
        tuto_response_4_1,
        tuto_response_4_2,
        tuto_question_5,
        tuto_response_5,
        tuto_link_5,
        tuto_thumbnail_5,
        tuto_question_6,
        tuto_response_6,
        tuto_link_6,
        tuto_thumbnail_6,
        tuto_question_7,
        tuto_response_7,
        tuto_link_7,
        tuto_thumbnail_7,
        tuto_question_8,
        tuto_response_8,
        tuto_link_8,
        tuto_thumbnail_8,
        tuto_question_9,
        tuto_response_9,
        tuto_link_9,
        tuto_thumbnail_9,
        tuto_question_10,
        tuto_response_10,
        tuto_link_10,
        tuto_thumbnail_10,
        tuto_question_11,
        tuto_response_11,
      },
    } = this.props;

    return (
      <section className="faq-wrapper">
        <h1>{subtitle_1}</h1>
        <h2 className="subtitle">{subtitle_2}</h2>
        {this.renderRow(
          tuto_question_1,
          [tuto_response_1],
          [tuto_link_1, tuto_thumbnail_1]
        )}
        {this.renderRow(
          tuto_question_2,
          [tuto_response_2],
          [tuto_link_2, tuto_thumbnail_2]
        )}
        {this.renderRow(tuto_question_3, [tuto_response_3])}
        {this.renderRow(
          tuto_question_4,
          [tuto_response_4_1, tuto_response_4_2],
          null,
          [wifi_tuto_1, wifi_tuto_2]
        )}
        {this.renderRow(
          tuto_question_5,
          [tuto_response_5],
          [tuto_link_5, tuto_thumbnail_5]
        )}
        {this.renderRow(
          tuto_question_6,
          [tuto_response_6],
          [tuto_link_6, tuto_thumbnail_6]
        )}
        {this.renderRow(
          tuto_question_7,
          [tuto_response_7],
          [tuto_link_7, tuto_thumbnail_7]
        )}
        {this.renderRow(
          tuto_question_8,
          [tuto_response_8],
          [tuto_link_8, tuto_thumbnail_8]
        )}
        {this.renderRow(
          tuto_question_9,
          [tuto_response_9],
          [tuto_link_9, tuto_thumbnail_9]
        )}
        {this.renderRow(
          tuto_question_10,
          [tuto_response_10],
          [tuto_link_10, tuto_thumbnail_10]
        )}
        {this.renderRow(tuto_question_11, [tuto_response_11])}
        {this.renderFooter()}
      </section>
    );
  }
}

export default FaqComponent;
