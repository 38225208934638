import React from 'react';
import { graphql } from 'gatsby';
import { shape } from 'prop-types';
import Layout from '../components/layout/layout';
import FaqComponent from '../components/faq/';
import {
  NavBar,
  Footer,
  Menu,
  Basket,
  pageWithNavigation,
} from '../components/common';

const FaqPage = ({ data }) => (
  <Layout
    title={data.wordings.fr.faq.page_title}
    description={data.wordings.fr.faq.description}
  >
    <NavBar withLogo basketClassName="yellow" />
    <Basket />
    <Menu />
    <FaqComponent wordings={data.wordings.fr.faq} />
    <Footer />
  </Layout>
);

FaqPage.propTypes = {
  data: shape({}).isRequired,
};

export default pageWithNavigation(FaqPage);

export const query = graphql`
  query FaqPage {
    wordings {
      fr {
        faq {
          page_title
          description
          subtitle_1
          subtitle_2
          tuto_question_1
          tuto_response_1
          tuto_link_1
          tuto_thumbnail_1
          tuto_question_2
          tuto_response_2
          tuto_link_2
          tuto_thumbnail_2
          tuto_question_3
          tuto_response_3
          tuto_question_4
          tuto_response_4_1
          tuto_response_4_2
          tuto_question_5
          tuto_response_5
          tuto_link_5
          tuto_thumbnail_5
          tuto_question_6
          tuto_response_6
          tuto_link_6
          tuto_thumbnail_6
          tuto_question_7
          tuto_response_7
          tuto_link_7
          tuto_thumbnail_7
          tuto_question_8
          tuto_response_8
          tuto_link_8
          tuto_thumbnail_8
          tuto_question_9
          tuto_response_9
          tuto_link_9
          tuto_thumbnail_9
          tuto_question_10
          tuto_response_10
          tuto_link_10
          tuto_thumbnail_10
          tuto_question_11
          tuto_response_11
          footer_description
          mail
          video
        }
      }
    }
  }
`;
